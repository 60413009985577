<template>
  <el-row>
    <el-col :span="8" style="padding-right:7px">
      <el-card class="box-card">
        <div class="user">
          <img src="../assets/images/QQ图片20210517210158.jpg" alt="">
          <div class="userinfo">
            <p class="name">Admin</p>
            <p class="access">超级管理员</p>
          </div>
        </div>
        <div class="login-info">
          <p>上次登录时间:<span>2022-2-2</span></p>
          <p>上次登录地点:<span>广东</span></p>
        </div>
      </el-card>
      <el-card style="margin-top:20px; height: 457px; ">
        <el-table :data="tableData" style="width: 100%;padding: 0px 0;font-size: 8px;">
          <el-table-column v-for="(val, key) in tableLabel" :key="key" :prop="key" :label="val" width="70">
          </el-table-column>
        </el-table>
      </el-card>
    </el-col>
    <el-col :span="16" style="padding-left:7px">
      <div class="num">
        <el-card v-for="item in countData" :key="item.name" :body-style="{ display: 'flex', padding: 0 }">
          <i class="icon" :class="`el-icon-${item.icon}`" :style="{ background: item.color }"></i>
          <div class="detail">
            <p class="price">¥{{ item.value }}</p>
            <p class="desc">{{ item.name }}</p>
          </div>
        </el-card>
      </div>
      <el-card style="height:280px;">
        <!-- 折线图 -->
        <div ref="echarts1" style="height:280px;"></div>
      </el-card>
      <div class="graph">
        <el-card style="height:230px;">
          <div ref="echarts2" style="height:230px;"></div>
        </el-card>
        <el-card style="height:230px;">
          <div ref="echarts3" style="height:190px;"></div>
        </el-card>
      </div>
    </el-col>
  </el-row>
</template>

<script>
import { getData } from '@/api'
import * as echarts from 'echarts';
export default {
  name: "Home",
  data() {
    return {
      tableData: [],
      tableLabel: {
        name: '课程',
        todayBuy: '今日购买',
        monthBuy: '本月购买',
        totalBuy: '总购买'
      },
      countData: [
        {
          name: "今日支付订单",
          value: 1234,
          icon: "success",
          color: "#2ec7c9",
        },
        {
          name: "今日收藏订单",
          value: 210,
          icon: "star-on",
          color: "#ffb980",
        },
        {
          name: "今日未支付订单",
          value: 1234,
          icon: "s-goods",
          color: "#5ab1ef",
        },
        {
          name: "本月支付订单",
          value: 1234,
          icon: "success",
          color: "#2ec7c9",
        },
        {
          name: "本月收藏订单",
          value: 210,
          icon: "star-on",
          color: "#ffb980",
        },
        {
          name: "本月未支付订单",
          value: 1234,
          icon: "s-goods",
          color: "#5ab1ef",
        },
      ]
    }
  },
  mounted() {
    getData().then(({ data }) => {
      const { tableData } = data.data;
      this.tableData = tableData

      // 基于准备好的dom，初始化echarts实例
      var echarts1 = echarts.init(this.$refs.echarts1, null, { width: 700, height: 250 });
      //指定图表的配置项和数据
      var echarts1Option = {}
      //处理xAxis
      const { userData, orderData,videoData } = data.data;
      const xAxis = Object.keys(orderData.data[0]);
      const xAxisData = {
        data: xAxis
      }
      echarts1Option.xAxis = {
        data: orderData.date
      }
      echarts1Option.yAxis = {}
      echarts1Option.legend = xAxisData
      echarts1Option.series = []
      xAxis.forEach(key => {
        echarts1Option.series.push({
          name: key,
          data: orderData.data.map(item => item[key]),
          type: 'line'
        })
      })

      echarts1.setOption(echarts1Option);

      //柱状图
      var echarts2 = echarts.init(this.$refs.echarts2);

      var echarts2Option = {
        legend: {
          // 图例文字颜色
          textStyle: {
            color: "#333",
          },
        },
        grid: {
          left: "20%",
        },
        // 提示框
        tooltip: {
          trigger: "axis",
        },
        xAxis: {
          type: "category", // 类目轴
          data: userData.map(item=>item.date),
          axisLine: {
            lineStyle: {
              color: "#17b3a3",
            },
          },
          axisLabel: {
            interval: 0,
            color: "#333",
          },
        },
        yAxis: [
          {
            type: "value",
            axisLine: {
              lineStyle: {
                color: "#17b3a3",
              },
            },
          },
        ],
        color: ["#2ec7c9", "#b6a2de", "#5ab1ef", "#ffb980", "#d87a80", "#8d98b3"],
        series: [
          {
            name:'新增用户',
            data:userData.map(item=>item.new),
            type:'bar'
          },
          {
            name:'活跃用户',
            data:userData.map(item=>item.active),
            type:'bar'
          }
        ],
      }
      echarts2.setOption(echarts2Option);

      //饼状图
      var echarts3 = echarts.init(this.$refs.echarts3);
      const echarts3Option={
        tooltip: {
            trigger: "item",
          },
          color: [
            "#0f78f4",
            "#dd536b",
            "#9462e5",
            "#a6a6a6",
            "#e1bb22",
            "#39c362",
            "#3ed1cf",
          ],
          series: [
            {
              data:videoData,
              type:'pie'
            }
          ],
      }
      echarts3.setOption(echarts3Option);
    })
  }
}
</script>

<style lang="less" scoped>
.user {
  display: flex;
  align-items: center;
  padding-bottom: 10px;
  margin-bottom: 10px;
  border-bottom: 1px solid #ccc;

  img {
    margin-right: 40px;
    width: 90px;
    height: 90px;
    border-radius: 50%;
  }

  .userinfo {
    .name {
      font-size: 25px;
      margin-bottom: 12px;
    }

    .access {
      color: #999999;
      font-size: 6px;
    }
  }
}

.login-info {
  p {
    line-height: 28px;
    font-size: 14px;
    color: #999999;

    span {
      color: #666666;
      margin-left: 60px;
    }
  }
}

.graph {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;

  .el-card {
    width: 48%;
  }
}

.num {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  .icon {
    width: 55px;
    height: 55px;
    font-size: 30px;
    text-align: center;
    line-height: 55px;
    color: #fff;
  }

  .detail {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-left: 15px;

    .price {
      font-size: 20px;
      margin-bottom: 5px;
    }

    .desc {
      font-size: 2px;
      color: #999;
      text-align: center;
    }
  }

  .el-card {
    width: 32%;
    margin-bottom: 20px;
  }
}
</style>