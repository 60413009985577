<template>
  <router-view></router-view>
</template>

<script>
export default {
  name: 'App',
  components: {

  }
}
</script>
<style lang="less">
html,
body,
h3,
p {
  margin: 0px;
  padding: 0px;
}
</style>
