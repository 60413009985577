<template>
  <h2>我是Mall</h2>
</template>

<script>
export default {
name:'Mall'
}
</script>

<style>

</style>